// Load it from the current package.
import { getThemeModule, loadThemeModule } from '@sightworks/theme';
import { ThemeProvider } from '@material-ui/styles';
import { useTheme, makeStyles, withStyles, responsiveFontSizes } from '@material-ui/core/styles';
import { ExtendedTheme, ExtendedThemeOptions, FontDetailItem } from './types';
import createMuiTheme from './createMuiTheme';
import type { StandardProps } from '@material-ui/core';


// @ts-ignore This is exported from the actual module.
import { styles } from '@material-ui/core/ScopedCssBaseline/ScopedCssBaseline';

import React, { Component, Fragment, useState, useEffect } from 'react';
import { Registry, BlockPropsBase, BoxProperties } from '@sightworks/block';
import clsx from 'clsx';

const updateTheme = Symbol('updateTheme');
const setTheme = Symbol('setTheme');
const initTheme = Symbol('initTheme');
const renderBase = Symbol('render');

const fontMap = new Map();
const emptyStyles = makeStyles({});
const googleFontMap = new Map<string, typeof emptyStyles>();

const useGoogleFont = (font: string): null => {
	let fontLoader = React.useRef(emptyStyles);
	let v = googleFontMap.get(font);
	if (!v) {
		fontLoader.current = v = makeStyles({ '@global': { '@import': [ 'url(https://fonts.googleapis.com/css?family=' + encodeURIComponent(font) + ')' ] } }, {name: 'GoogleFont'})
		if (typeof window != 'undefined') {
			googleFontMap.set(font, v);
		}
	}
	fontLoader.current();
	return null;
}

const FontLoader = (): JSX.Element => {
	const theme = useTheme<ExtendedTheme>();
	return <>{(theme.fonts || []).map((font, index) => <Load font={font} key={index}/>)}</>;
}

const GoogleFontLoader = (): JSX.Element => {
	const theme = useTheme<ExtendedTheme>();
	return <>{(theme.googleFonts || []).map((font, index) => <GoogleLoad font={font} key={index}/>)}</>;
}

const useFont = (font: FontDetailItem): null => {
	let key = [ font.cssFontFamily, font.fontWeight, font.fontStyle ].join(':');
	let fontStyle = fontMap.get(key);
	if (!fontStyle) {
		let eot = font.sources.find(({ type }) => type == 'eot');
		let src = [
			[
				...(font.localNames || []).map(v => `local("${v}")`),
				...font.sources.map(src => {
					let type: string = src.type;
					if (type == 'eot') type = 'embedded-opentype';
					else if (type == 'ttf') type = 'truetype';
					else if (type == 'otf') type = 'opentype';
					return `url("${src.path}${src.type == 'svg' ? `#${src.svgId}` : ''}") format("${type}")`
				})
			].join(', '),
			eot ? `url(${eot.path}?#iefix)` : null
		].filter(v => !!v);
		fontStyle = makeStyles({
			'@global': {
				'@font-face': {
					fontFamily: font.cssFontFamily,
					fontWeight: font.fontWeight,
					fontStyle: font.fontStyle,
					src: src[0],
					...(src.length > 1 ? {
						fallbacks: src.slice(1).map(v => ({ src: v }))
					} : {})
				}
			}
		}, { name: 'FontFace' });

		fontMap.set(key, fontStyle);
	}

	fontStyle();
	return null;
}

const GoogleLoad = ({ font }: { font: string }): JSX.Element => {
	useGoogleFont(font);
	return null;
}

const Load = ({ font }: { font: FontDetailItem }): JSX.Element => {
	useFont(font);
	return null;
}

const useGlobalPageStyles = makeStyles(
	theme => ({
		root: {
			'--divider': `1px solid ${theme.palette.divider}`,
			[theme.breakpoints.only('xs')]: {
				'--width-small': '80%',
				'--width-medium': '85%',
				'--width-large': '90%',
				'--margin-small': `${theme.spacing(1)}px`,
				'--margin-medium': `${theme.spacing(2)}px`,
				'--margin-large': `${theme.spacing(4)}px`,
			},
			[theme.breakpoints.up('sm')]: {
				'--width-small': '60%',
				'--width-medium': '80%',
				'--width-large': '90%',
				'--margin-small': `${theme.spacing(1)}px`,
				'--margin-medium': `${theme.spacing(4)}px`,
				'--margin-large': `${theme.spacing(8)}px`,
			},
			'& a[href^="tel:"]': {
				textDecoration: 'none',
				color: 'inherit'
			}
		},
		withWidth: {
			'&:not($withAlignLeft):not($withAlignRight):not($withAlignCenter)': {
				marginLeft: 'auto',
				marginRight: 'auto',
			},
			width: 'var(--selected-width)',
		},
		withSmallWidth: {
			width: 'var(--width-small, 80%)',
		},
		withMediumWidth: {
			width: 'var(--width-medium, 85%)',
		},
		withLargeWidth: {
			width: 'var(--width-large, 90%)',
		},
		withTopMargin: {
			marginTop: `var(--computed-top-margin)`,
		},
		withBottomMargin: {
			marginBottom: `var(--computed-bottom-margin)`,
		},
		withAlignLeft: {
			'&$withWidth': {
				marginRight: 'auto'
			}
		},
		withAlignRight: {
			'&$withWidth': {
				marginLeft: 'auto'
			}
		},
		withAlignCenter: {
			'&$withWidth': {
				marginLeft: 'auto',
				marginRight: 'auto'
			}
		},
		withTopDivider: {
			'--computed-top-divider': `var(--divider, 1px solid ${theme.palette.divider})`,
			borderTop: `var(--computed-top-divider)`,
			'&$withTopMargin': {
				marginTop: 0,
				paddingTop: 'var(--computed-top-margin)',
			},
		},
		withBottomDivider: {
			'--computed-bottom-divider': `var(--divider, 1px solid ${theme.palette.divider})`,
			borderBottom: `var(--computed-bottom-divider)`,
			'&$withBottomMargin': {
				marginBottom: 0,
				paddingBottom: 'var(--computed-bottom-margin)',
			},
			'& + $withTopDivider': {
				borderTop: 'none',
			},
		},
		withSmallTopMargin: {
			'--computed-top-margin': `var(--margin-small, ${theme.spacing(1)}px)`,
		},
		withMediumTopMargin: {
			'--computed-top-margin': `var(--margin-medium, ${theme.spacing(2)}px)`,
		},
		withLargeTopMargin: {
			'--computed-top-margin': `var(--margin-large, ${theme.spacing(4)}px)`,
		},
		withSmallBottomMargin: {
			'--computed-bottom-margin': `var(--margin-small, ${theme.spacing(1)}px)`,
		},
		withMediumBottomMargin: {
			'--computed-bottom-margin': `var(--margin-medium, ${theme.spacing(2)}px)`,
		},
		withLargeBottomMargin: {
			'--computed-bottom-margin': `var(--margin-large, ${theme.spacing(4)}px)`,
		},
	}),
	{
		name: 'SwGlobal',
		index: 10000,
	}
);

type ThemeArg = Partial<ExtendedTheme> | ((outerTheme: ExtendedThemeOptions) => ExtendedTheme);

export default function ThemeLoader<P extends BlockPropsBase = BlockPropsBase>(parent: React.FC<P> | React.ForwardRefRenderFunction<P, any>, defaultTheme: ThemeArg = null, defaultNamedTheme: string = null): React.ForwardRefExoticComponent<P> & { defaultTheme: ThemeArg } {
	let target = parent;
	if (parent.length == 2) target = React.forwardRef(parent  as React.ForwardRefRenderFunction<P, any>);

	type UseGlobalStylesProps = {
		theme: ThemeArg,
		component: React.FC<P> | React.ForwardRefExoticComponent<P>,
		targetProps: P,
		targetRef: React.Ref<any>,
		boxProperties: BoxProperties
	};

	const UseGlobalStyles = ({ theme, ...rest }: UseGlobalStylesProps) => {
		return (
			<ThemeProvider theme={theme}>
				<FontLoader/>
				<GoogleFontLoader/>
				<UseGlobalStylesInner {...rest} />
			</ThemeProvider>
		);
	};

	type ApplyBoxPropertiesProps = Pick<UseGlobalStylesProps, Exclude<keyof UseGlobalStylesProps, "theme">> & { targetClassName?: string, withGlobalOverrides?: boolean };

	const ApplyBoxProperties = ({ boxProperties, component, targetProps, targetRef, targetClassName }: ApplyBoxPropertiesProps) => {
		const rest = { ...targetProps };
		const { classes, className } = targetProps;
		const theme = useTheme<ExtendedTheme>();
		// console.log(theme.globalStyles, boxProperties);
		boxProperties = boxProperties || {
			width: null,
			margins: {},
			dividers: {},
			align: null
		};

		const root = clsx(
			targetClassName ?? '',
			className,
			(classes || {}).root ?? '',
			boxProperties.width != 'none' && theme.globalStyles.width[boxProperties.width] || '',
			boxProperties.margins?.top != 'none' && theme.globalStyles.margins.top[boxProperties.margins.top] || '',
			boxProperties.margins?.bottom != 'none' && theme.globalStyles.margins.bottom[boxProperties.margins.bottom] || '',
			{
				[theme.globalStyles.dividers.top]: boxProperties.dividers.top,
				[theme.globalStyles.dividers.bottom]: boxProperties.dividers.bottom,
			},
			(boxProperties.align && theme.globalStyles.align[boxProperties.align]) ?? ''
		);

		const targetClasses = { ...(classes || {}), root };
		return React.createElement(component, {
			...rest,
			classes: targetClasses,
			className: root,
			ref: targetRef,
		});
	};

	type UseGlobalStylesInnerProps = Pick<UseGlobalStylesProps, Exclude<keyof UseGlobalStylesProps, "theme">>;
	const UseGlobalStylesInner = (props: UseGlobalStylesInnerProps) => {
		const currentTheme = useTheme<ExtendedTheme>();
		const globalStyles = useGlobalPageStyles();
		currentTheme.globalStyles = {
			base: {
				width: globalStyles.withWidth,
				marginTop: globalStyles.withTopMargin,
				marginBottom: globalStyles.withBottomMargin,
				dividerTop: globalStyles.withTopDivider,
				dividerBottom: globalStyles.withBottomDivider,
			},
			width: {
				small: clsx(globalStyles.withWidth, globalStyles.withSmallWidth),
				medium: clsx(globalStyles.withWidth, globalStyles.withMediumWidth),
				large: clsx(globalStyles.withWidth, globalStyles.withLargeWidth),
			},
			margins: {
				top: {
					small: clsx(globalStyles.withTopMargin, globalStyles.withSmallTopMargin),
					medium: clsx(globalStyles.withTopMargin, globalStyles.withMediumTopMargin),
					large: clsx(globalStyles.withTopMargin, globalStyles.withLargeTopMargin),
				},
				bottom: {
					small: clsx(globalStyles.withBottomMargin, globalStyles.withSmallBottomMargin),
					medium: clsx(globalStyles.withBottomMargin, globalStyles.withMediumBottomMargin),
					large: clsx(globalStyles.withBottomMargin, globalStyles.withLargeBottomMargin),
				},
			},
			dividers: {
				top: globalStyles.withTopDivider,
				bottom: globalStyles.withBottomDivider,
			},
			align: {
				left: globalStyles.withAlignLeft,
				right: globalStyles.withAlignRight,
				center: globalStyles.withAlignCenter
			}
		};

		return <ScopedCssBaseline>
			<ApplyBoxProperties {...props} targetClassName={globalStyles.root}  />
		</ScopedCssBaseline>;
	};

	const Loader = (props: P, ref: React.Ref<any>) => {
		const currentTheme = useTheme();
		const namedTheme = props.useNamedTheme
			? Registry.makeNamedTheme(props.useNamedTheme, currentTheme)
			: (defaultNamedTheme ? Registry.makeNamedTheme(defaultNamedTheme, currentTheme) : currentTheme);

		const prepare = (theme: ThemeArg): ExtendedTheme => {
			if (typeof theme === 'function') {
				theme = theme(namedTheme || {});
			}
			let td = createMuiTheme(theme);
			return responsiveFontSizes(td);
		};

		const [theme, setTheme] = useState<ThemeArg>(Loader.defaultTheme);

		const updateTheme = () => {
			setTheme(defaultTheme);
		};

		useEffect(() => {
			updateTheme();
		}, []);

		const { useNamedTheme: __, boxProperties } = props;
		const rest = { ...props };
		rest.boxProperties = null;
		rest.useNamedTheme = null;

		let inner;
		// let inner = React.createElement(target, { ...rest, ref });
		if (theme || Loader.defaultTheme) {
			inner = (
				<UseGlobalStyles
					theme={theme || Loader.defaultTheme}
					component={target}
					targetProps={rest}
					targetRef={ref}
					boxProperties={boxProperties}
				/>
			);
		} else if (namedTheme != currentTheme) {
			inner = (
				<UseGlobalStyles
					theme={namedTheme}
					component={target}
					targetProps={rest}
					targetRef={ref}
					boxProperties={boxProperties}
				/>
			);
		} else if (boxProperties) {
			inner = (
				<ApplyBoxProperties boxProperties={boxProperties} component={target} targetProps={rest} targetRef={ref} />
			);
		} else {
			inner = React.createElement(target, { ...rest, ref });
		}

		return inner;
	};

	Loader.displayName = `ThemeLoader(${parent.displayName || parent.name || 'Anonymous'})`;
	Loader.defaultTheme = defaultTheme;
	const R = React.forwardRef(Loader);
	Object.defineProperty(R, 'defaultTheme', {
		get() {
			return Loader.defaultTheme;
		},
		set(v) {
			Loader.defaultTheme = v;
		},
	});
	// TS doesn't see the 'defineProperty' above.
	return R as unknown as React.ForwardRefExoticComponent<P> & { defaultTheme: ThemeArg };
}

interface ScopedCssBaselineProps extends StandardProps<React.HTMLAttributes<HTMLDivElement>, 'root'> {
	children?: React.ReactNode;
}

function stylesWithoutBackground(theme: any) {
	let x = styles(theme);
	delete x.root.backgroundColor;
	return x;
}

export const ScopedCssBaseline = withStyles(stylesWithoutBackground, { name: 'SwScopedCssBaseline' })((props: ScopedCssBaselineProps): JSX.Element => {
	let { classes, className, ...other } = props;
	return <div className={clsx(classes.root, className)}>{props.children}</div>
});
