import React, { useCallback, useRef, useReducer, useEffect } from 'react';
import { useBreakpoint } from '@sightworks/block';
import MuiButton from '@material-ui/core/Button';
import MuiFormControl from '@material-ui/core/FormControl';
import MuiInputLabel from '@material-ui/core/InputLabel';
import MuiFilledInput from '@material-ui/core/FilledInput';
import MuiIcon from '@material-ui/core/Icon';

import clsx from 'clsx';
import { Theme, InputBaseComponentProps } from '@material-ui/core';
import { CSSProperties } from '@material-ui/styles';
import FileUploadBody from './body';
import { type } from 'jquery';
import { UploadInfo } from './question';

function doUploadFile(file: File, target: string, dispatcher: React.Dispatch<FileUploadReducerAction>): Promise<Response> {
	let request = new XMLHttpRequest();
	request.open('POST', `${target}/${file.name}`, true);

	let P = new Promise<Response>((resolve) => {
		request.responseType = 'blob';
		request.addEventListener('readystatechange', event => {
			if (request.readyState == XMLHttpRequest.DONE) {
				let H: HeadersInit;
				let X = request.getAllResponseHeaders();
				let X1 = X.split('\r\n');
				X1 = X1.filter(v => !!v);
				let X2 = X1.map(h => h.split(':'));
				X2 = X2.map(([a, ...b]) => [a.trim(), b.join(':').trim()]);
				let X3 = X2.reduce((acc, [key, val]) => ({
					...acc,
					[key]: val
				}), {} as Record<string, string>);
				H = X3;

				resolve(new Response(request.response, {
					status: request.status,
					statusText: request.statusText,
					headers: H
				}));
			}
		})
	})

	request.upload.addEventListener('loadstart', event => {
		dispatcher({ action: 'loadStarted' });
	});
	request.upload.addEventListener('progress', event => {
		dispatcher({ action: 'loadProgress', progress: event.lengthComputable ? event.loaded / event.total : null })
	});
	request.upload.addEventListener('load', event => {
		dispatcher({ action: 'loadFinished' })
	});

	request.setRequestHeader('Content-Type', 'application/octet-stream');
	request.send(file);

	return P;
}

type FileUploadReducerAction = ClearFile | SetFile | StartUpload | LoadStarted | LoadProgress | LoadFinished | Complete;
type ClearFile = { action: 'clearFile' };
type SetFile = { action: 'setFile', file: File };
type StartUpload = { action: 'startUpload', dispatch: React.Dispatch<FileUploadReducerAction>, target: string, resolve: (value: UploadInfo) => void }
type LoadStarted = { action: 'loadStarted' };
type LoadProgress = { action: 'loadProgress', progress: number };
type LoadFinished = { action: 'loadFinished' };
type Complete = { action: 'complete', result: UploadInfo };

type FileUploadState = {
	status: 'NONE' | 'READY' | 'START' | 'SENDING' | 'SENT' | 'COMPLETE';
	file: File;
	percent: number;
	fileUpload: Promise<UploadInfo>;
	result: UploadInfo;
}

function fileUploadReducer(state: FileUploadState, action: FileUploadReducerAction): FileUploadState {
	switch (action.action) {
		case 'clearFile':
			if (state.status != 'READY') return state;
			return { ...state, status: 'NONE', file: null, percent: 0, fileUpload: null, result: null };

		case 'setFile':
			if (state.status != 'NONE' && state.status != 'READY') return state;
			if (action.file)
				return { ...state, status: 'READY', file: action.file, percent: 0, fileUpload: null, result: null };
			return state;

		case 'startUpload':
			if (state.status != 'READY') {
				return state;
			}

			let dispatcher = action.dispatch;
			let target = action.target;
			let resolve = action.resolve;

			return {
				...state,
				status: 'START',
				fileUpload: new Promise(rp => {
					requestAnimationFrame(() => {
						rp(doUploadFile(state.file, target, dispatcher).then(async response => {
							let r = await response.json();
							dispatcher({ action: 'complete', result: r });
							resolve(r);
							return r;
						}));
					});
				})
			};

		case 'loadStarted':
			if (state.status != 'START')
				return state;

			return {
				...state,
				status: 'SENDING',
				percent: 0
			}

		case 'loadProgress':
			if (state.status != 'SENDING')
				return state;

			return {
				...state,
				status: 'SENDING',
				percent: action.progress
			}

		case 'loadFinished':
			if (state.status != 'SENDING')
				return state;

			return {
				...state,
				status: 'SENT',
				percent: 1
			}

		case 'complete':
			if (state.status != 'SENT')
				return state;

			return {
				...state,
				status: 'COMPLETE',
				result: action.result
			}
	}
}
const FileUploadField = ({ dispatch, id, error, title, classes, value, types, extensions, status }: FileUploadBody) => {
	const [ fileUploadState, uploadDispatch ] = useReducer(fileUploadReducer, {
		file: null,
		status: 'NONE',
		percent: 0,
		fileUpload: null,
		result: null
	});

	const callback = useCallback(event => {
		let file = event.target.files[0];
		if (extensions) {
			let e = extensions.split(/\s+/);
			let v = e.find(file.split('.').pop());
			if (!v) {
				alert(file.name + " is not a valid selection.");
				event.target.value = "";
				return;
			}
		}
		if (!file) {
			uploadDispatch({ action: 'setFile', file: null });
		} else {
			uploadDispatch({ action: 'setFile', file });
		}
	}, [ dispatch, id ]);

	useEffect(() => {
		if (fileUploadState.status == 'NONE')
			dispatch({ action: 'setField', data: { [id]: null } });
	}, [ fileUploadState.status == 'NONE' ]);
	useEffect(() => {
		if (fileUploadState.status == 'READY')
			dispatch({ action: 'setField', data: {
				[id]: {
					toString() { return fileUploadState.file.name },
					getValue(target) {
						return new Promise(resolve => {
							uploadDispatch({
								action: 'startUpload',
								resolve,
								dispatch: uploadDispatch,
								target
							})
						});
					}
				}
			}});
	}, [ fileUploadState.status == 'READY', fileUploadState.file ]);

	useEffect(() => {}, [ fileUploadState.status, fileUploadState.percent ]);

	const breakpoint = useBreakpoint();
	const ref = useRef();

	return (
		<div className={clsx(classes.field, classes.fileUploadFieldWrap)}>
			<input className={classes.fileUploadField} id={`file_${id}`} type="file" onChange={callback} {...(types == '*/*' ? {} : { accept: types })} ref={ref} />
			<MuiFormControl className={classes.fileUploadControlRoot} variant="filled">
				<MuiInputLabel htmlFor={`file_${id}`} id={`input_${id}`}>
					{title}
				</MuiInputLabel>
				<MuiFilledInput inputComponent={FileUploadFieldInner} value={value} inputProps={{ id, inputField: ref, outerClasses: classes, dispatch: uploadDispatch, fileUploadState }} className={classes.fileUploadName}/>
			</MuiFormControl>
		</div>
	);
}

type FileUploadFieldInnerProps = InputBaseComponentProps & {
	id: string;
	inputField: React.MutableRefObject<any>,
	outerClasses: globalThis.Record<string, string>,
	dispatch: React.Dispatch<FileUploadReducerAction>,
	fileUploadState: FileUploadState
}
const FileUploadFieldInner = (props: FileUploadFieldInnerProps) => {
	let cb = useCallback(() => {
		props.inputField.current.value = "";
		props.dispatch({ action: 'clearFile' });
	}, [props.inputField, props.id, props.dispatch]);

	return (
		<div className={clsx(
			props.outerClasses.fileUploadProgressWrap,
			{
				[props.outerClasses.fileUploadStateNone]: props.fileUploadState.status == 'NONE',
				[props.outerClasses.fileUploadStateReady]: props.fileUploadState.status == 'READY',
				[props.outerClasses.fileUploadStateStart]: props.fileUploadState.status == 'START',
				[props.outerClasses.fileUploadStateSending]: props.fileUploadState.status == 'SENDING',
				[props.outerClasses.fileUploadStateIndeterminate]: props.fileUploadState.status == 'SENDING' && props.fileUploadState.percent === null,
				[props.outerClasses.fileUploadStateSent]: props.fileUploadState.status == 'SENT',
				[props.outerClasses.fileUploadStateComplete]: props.fileUploadState.status == 'COMPLETE'
			}
		)} style={{ '--progress-percent': `${Math.round(100 * props.fileUploadState.percent)}%` } as CSSProperties}>
			<label htmlFor={`file_${props.id}`} className={props.className}>
				{String(props.value || "")}
			</label>
			{props.value && (
				<MuiButton variant="text" color="default" onClick={cb} className={clsx(props.className, props.outerClasses.clearButton)}>
					<MuiIcon>close</MuiIcon>
				</MuiButton>
			)}
		</div>
	);
};

export default FileUploadField;
export const Style = (theme: Theme): globalThis.Record<string, CSSProperties> => ({
	'@keyframes progressBar': {
		from: {
			backgroundPosition: '0px 0px'
		},
		to: {
			backgroundPosition: '28px 0px'
		}
	},
	fileUploadProgressWrap: {
		position: 'relative',
		display: 'flex',
		flexDirection: 'row',
		flex: '1 1 auto',
		'&:before': {
			position: 'absolute',
			top: 0,
			left: 0,
			bottom: 0,
			width: '0%',
			backgroundImage: `repeating-linear-gradient(135deg, ${theme.palette.secondary.light}, ${theme.palette.secondary.light} 10px, ${theme.palette.secondary.main} 10px, ${theme.palette.secondary.main} 20px)`,
			backgroundPosition: '0px 0px',
			backgroundSize: '28px 28px',
			backgroundRepeat: 'repeat',
			animationName: '$progressBar',
			animationDuration: '2s',
			animationIterationCount: 'infinite',
			animationTimingFunction: 'linear',
			animationDirection: 'normal'
		}
	},
	fileUploadStateNone: {},
	fileUploadStateReady: {},
	fileUploadStateStart: {
		'&:before': {
			content: '""'
		}
	},
	fileUploadStateSending: {
		'&:before': {
			content: '""',
			width: 'var(--progress-percent)'
		},
		'&$fileUploadStateIndeterminate:before': {
			width: '100%'
		}
	},
	fileUploadStateIndeterminate: {},
	fileUploadStateSent: {
		'&:before': {
			content: '""',
			width: '100%'
		}
	},
	fileUploadStateComplete: {
		'&:before': {
			content: '""',
			width: '100%'
		}
	},
	fileUploadFieldWrap: {
		flex: 1
	},
	fileUploadControlRoot: {
		flex: 1,
		width: '100%'
	},
	fileUploadField: {
		display: 'none'
	},
	fileUploadName: {
		display: 'flex',
		'& $fileUploadProgressWrap > label': {
			flex: '1 1 100%'
		},
		'& $fileUploadProgressWrap > label > span:first-child': {
			whiteSpace: 'nowrap',
			overflow: 'hidden',
			textOverflow: 'ellipsis',
			textTransform: 'none'
		}
	},
	clearButton: {
		padding: '18.5px 12px',
		flex: '0 0 auto',
		width: 'min-content',
		display: 'block'
	}
});
