import React, { useState, useEffect, useRef, Component, createRef, Fragment, useMemo } from 'react';
import { ThemeLoader, StyleLoader, ExtendedTheme, useTheme } from '@sightworks/theme';
import Carousel from './carousel';
import { makeStyles } from '@material-ui/core/styles';
import getChildren, { flattenChildren } from '../../utils/children';
import CarouselProps from './props';
import { BlockPropsBase } from '../..';
import { OpaqueContent } from '@sightworks/block';
import { ThemeProvider } from '@material-ui/styles';

let w: <T>(node: T) => T = node => node;

let getFlatChildren = (content: OpaqueContent, didResize: any) => {
	let r = flattenChildren(content).map(child => getChildren([ { ...child, key: child.id } as BlockPropsBase ], null, true));
	return flatten(r);
}

type NestedArrayOf<T> = T | (T | NestedArrayOf<T>)[];

function flatten<T>(array: NestedArrayOf<T>): T[] {
	if (Array.isArray(array)) {
		return array.reduce<T[]>((acc: T[], val: NestedArrayOf<T>) => {
			return acc.concat(flatten<T>(val));
		}, [] as T[]);
	} else {
		return [array];
	}
}

const CarouselBlock = ({ classes, carousel, content }: CarouselProps, ref: React.Ref<any>) => {
	const [didResize, setDidResize] = useState(null);
	const [reset, setReset] = useState(0);
	const value = useRef(carousel);

	let items = getFlatChildren(content, didResize);
	useEffect(() => {
		if (JSON.stringify(carousel) != JSON.stringify(value.current)) { setReset(v => v + 1); value.current = carousel; }
	}, [carousel])

	return (
		<Carousel {...carousel} classes={classes} key={reset}>
			{items}
		</Carousel>
	);
};

/*
// This is to interact with Slick, when it changes up the size of it's content...
type SlideProps = {
	node: BlockPropsBase;
	onResize?(): Promise<boolean>;
	getRawNode?(props: { resized: Promise<boolean>, shouldResize(): void }): JSX.Element;

	className?: string;
	'data-index'?: number;
	'aria-hidden'?: boolean;
	onClick?: React.MouseEventHandler<HTMLDivElement>;
	style?: React.CSSProperties;
	tabIndex?: string;	
};

class Slide extends Component {
	props: SlideProps;
	_root: HTMLElement;
	_observer: MutationObserver;
	_observer2: MutationObserver;
	state: {
		value:   Promise<boolean>,
		resolve: (value: boolean | Promise<boolean>) => void,
		reject:  (value: any) => void;
	};
	constructor(props: SlideProps) {
		super(props);
		this._gotRoot = this._gotRoot.bind(this);
		this._nodeChanged = this._nodeChanged.bind(this);
		this.state = this._newState();
	}

	_newState() {
		let r: { resolve: (v: boolean) => void, reject: (v: any) => void };
		const rv = {
			value: new Promise<boolean>((resolve, reject) => {
				r = { resolve, reject };
			}),
		};
		return { ...rv, ...r };
	}

	_gotRoot(node: HTMLElement) {
		if (this._root != node) {
			if (this._root) this._unbind();
			this._root = node;
			if (this._root) this._bind();
		}
	}

	_unbind() {
		this._observer.disconnect();
		this._observer = null;
	}

	_bind() {
		this._observer = new MutationObserver(this._nodeChanged);
		let tgt: Node = this._root;
		do {
			if (tgt instanceof HTMLElement && tgt.classList && tgt.classList.contains('slick-slide')) {
				break;
			}
			tgt = tgt.parentNode;
		} while (tgt);
		if (tgt) {
			this._observer.observe(tgt, {
				attributeFilter: ['style'],
				attributes: true
			});
		}
	}

	_nodeChanged() {
		this.props.onResize?.().then(value => {
			this.state.resolve(value);
			this.setState(this._newState());
		});
	}

	componentWillUnmount() {
		this.state.reject(true);
	}

	getContent() {
		if (this.props.getRawNode) {
			return this.props.getRawNode({ resized: this.state.value, shouldResize: this._nodeChanged });
		}
		return getChildren([ { ...this.props.node, resized: this.state.value, shouldResize: this._nodeChanged } as BlockPropsBase ]);
	}

	render() {
		let { node, onResize, getRawNode, tabIndex, ...rest } = this.props;
		return (
			<div ref={this._gotRoot} {...rest} tabIndex={tabIndex ? Number(tabIndex) : void 0}>
				<Disabler disabled={tabIndex == '-1'}>
					<div data-item>
						{this.getContent()}
					</div>
				</Disabler>
			</div>
		);
	}
}

*/

const Disabler = (props: React.PropsWithChildren<{ disabled: boolean }>): JSX.Element => {
	let { disabled, children } = props;
	return <>{children}</>;
}

const ThemedCarouselBlock = ThemeLoader(StyleLoader(CarouselBlock));

export default ThemedCarouselBlock;
